import React, { useEffect, useState } from "react";
import { getDay_month_yearFormated } from "../../../../utils/convertTodateString";
import Loader from "../../../UserComponents/Loader";
import axiosInstance from "../../../../utils/api/axiosInstance";
import { ADMIN_URL } from "../../../../constants";
import { useNavigate, useParams } from "react-router-dom";
import OrderItem from "./OrderItem";
import OrdersList from "../index";
import { MapIcon, MapPin } from "lucide-react";

const OrderDetail = () => {
  const [order, setOrder] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { orderId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    axiosInstance(ADMIN_URL + `/order/${orderId}`).then(({ data }) => setOrder(data.data)).catch((e) => setError(e)).finally(() => setIsLoading(false));
  }, [orderId]);

  if (error) {
    navigate(-1);
    return;
  }

  return (
    <div className={`p-5 w-full bg-gray-50 dark:bg-dark`}>
     
        <>

          <div className="grid grid-cols-4 mt-10">
            <OrdersList _id={'112'} />
            <div className="col-span-3 ml-4">
            {isLoading ? (<Loader className={'h-[500px]'} />) : (
              <>
                <div className="flex flex-col w-full ">
                  <div className="border dark:border-gray-800 bg-white-A700 py-3 px-3 w-full flex justify-between items-center rounded-lg dark:bg-dark-card">                   
                   <p className="text-lg font-semibold flex items-center gap-2"><MapPin className="text-gray-400" /> {order?.address?.name}</p>
                    <p><span className="font-semibold">Address:</span> {order?.address?.address}, <span className="font-semibold">City:</span> {order?.address?.pin}</p>
                    <p className="text-lg font-semibold"><span className="font-semibold">Phone:</span> {order?.address?.phone}</p>
                  </div>
                </div>
             
              <div className="mt-7 ">
              <h2 className="text-2xl font-semibold mb-3">Products</h2>
                <div className="flex flex-col gap-2 ">{order?.products.map((item) => (
                  <OrderItem {...item} key={item._id} orderId={order?._id} />))}
                </div>
              </div>
              </>
            )}
            </div>
          
          </div>
        </>
     
    </div>
  );
};

export default OrderDetail;
