import React from "react";
import { Helmet } from "react-helmet";
import { Button, Text, Heading, Img } from "../../../components/UserComponents";
import Footer from "../../../components/UserComponents/Footer";
import Header from "../../../components/UserComponents/Header";
import { backgroundPng } from "../../../assets/images";
import { useNavigate } from "react-router-dom";
import CategoryBanners from "../../../components/UserComponents/Home/CategoryBanners";
import TopProducts from "../../../components/UserComponents/Home/TopProducts";
import LatestProducts from "../../../components/UserComponents/Home/LatestProducts";
import HeroSection from "../../../components/UserComponents/Home/Hero/HeroSection";
import FeaturesSection from "../../../components/UserComponents/Home/FeaturesSection";
import BrandList from "../../../components/UserComponents/brandList";
import FeaturedProducts from "../../../components/UserComponents/Home/FeaturedProducts";
import PolicyListHorizontal from "../../../components/UserComponents/policyList/policyListHorizontal";
import NewsLetter from "../../../components/UserComponents/Home/newsLetterSubscription";
import DealShowCase from "../../../components/UserComponents/Home/DealShowCase";
import { Index } from "react-instantsearch";

export default function HomePage() {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>
          Shop the Latest Fashion and Accessories - Home | Elite Digitals
        </title>
        <meta
          name="description"
          content="Discover our exclusive collection with free shipping on orders over $50. Explore best sellers and new arrivals in fashion and accessories. Start your shopping journey with Elite Digitals."
        />
      </Helmet>
      <div className="w-full bg-white-A700">
        {/* header section */}
        <Header />
        <div className="flex flex-col pb-[52px] md:pb-5">
          {/* hero slider section */}
          <HeroSection />

          <div className="container mx-auto md:hidden">
            <PolicyListHorizontal/>
          </div>



          <CategoryBanners/>

          <div className="mt-20 md:mt-5">
            <BrandList/>
          </div>






          <div className="flex flex-col items-center">

            {/* Category section  */}
           {/* <CategoryBanners />*/}

           <Index indexName="products_books">  
              <FeaturedProducts/>
            </Index>

            {/* banner section */}
            <div className="container relative mt-[52px] h-[437px] md:h-[350px]  rounded-[24px] overflow-hidden bg-gray-100 md:p-5 shadow-lg my-10 md:rounded-none">
              <Img src='/images/christmas-banner.png' alt="backgroundimage" className="absolute bottom-0 right-[0.00px] top-0 my-auto h-[437px] md:h-[350px] w-[100%] object-cover " />
              <div className="absolute bottom-0 left-[0.00px] top-0 my-auto flex h-max w-[75%] md:w-[90%] py-[93px] pl-[120px] pr-14 md:p-5">
                <div className="flex w-[69%] flex-col items-start gap-[23px] md:w-full">
                  <div className="flex flex-col gap-3 md:w-full">             
                    <p className="text-gray-500 font-semibold text-lg">100% Authentic Holiday Gifts</p>                  
                    <h1 className="font-bold text-5xl md:text-3xl">Exclusive Christmas <br /> Collection</h1>
                    <p className="text-gray-500 text-xl">Starting from: $39.99</p> 
                  </div>
                  <Button color="gray_900" size="md" shape="round" className="min-w-[144px] font-medium sm:px-5" onClick={() => navigate("/shop?q=&category[0]=Christmas&page=1")}>Shop now</Button>
                </div>
              </div>
            </div>

            {/* Latest Products */}
            {/*<LatestProducts />*/}

            {/* bestsellers section */}

            <Index indexName="products">  
              <TopProducts />
            </Index>

            <Index indexName="products_furniture">  
              <DealShowCase/>
            </Index>


          </div>
        </div>
        
        <NewsLetter />
        <Footer />
      </div>
    </>
  );
}
