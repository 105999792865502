import React from "react";
import { Avatar, Box, Flex, FormLabel, Icon, Select, SimpleGrid, useColorModeValue } from "@chakra-ui/react";
import MiniStatistics from "../../../components/AdminComponents/card/MiniStatistics";
import IconBox from "../../../components/AdminComponents/icons/IconBox";
import { MdAddTask, MdAttachMoney, MdBarChart, MdFileCopy } from "react-icons/md";
import DailyTraffic from "../../../components/AdminComponents/Dashboard/DailyTraffic";
import PieCard from "../../../components/AdminComponents/Dashboard/PieCard";
import TotalSpent from "../../../components/AdminComponents/Dashboard/TotalSpent";
import WeeklyRevenue from "../../../components/AdminComponents/Dashboard/WeeklyRevenue";
import { darkCardBg } from "../../../constants";
import { useEffect, useState } from "react";
import axiosInstance, { axiosPublicInstance } from "../../../utils/api/axiosInstance";
import { ADMIN_URL } from "../../../constants";
import Loader from "../../../components/UserComponents/Loader";


const Dashboard = () => {
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("gray.50", darkCardBg);
  const [dashboardData, setDashboardData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axiosInstance(ADMIN_URL + `/dashboard`)
      .then(({ data }) => setDashboardData(data.data))
      .catch((error) => setError(error))
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) return <Box><Loader/></Box>;
  if (error) return <Box>Error loading dashboard data</Box>;
  if (!dashboardData) return null;

  return (
    <Box>
      <Box pt={{ base: "130px", md: "80px", xl: "40px" }} p="5">
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }} gap="15px" mb="10px">
          <MiniStatistics 
            startContent={<IconBox w="56px" h="56px" bg={boxBg} icon={<Icon w="32px" h="32px" as={MdBarChart} textColor={brandColor} />} />} 
            name="Total Earnings" 
            value={`$${dashboardData.order.totalEarnings.totalEarning.toFixed(2)}`} 
          />
          
          <MiniStatistics 
            startContent={<IconBox w="56px" h="56px" bg={boxBg} icon={<Icon w="32px" h="32px" as={MdAttachMoney} textColor={brandColor} />} />} 
            name="Revenue This Month" 
            value={`$${dashboardData.order.revenuePerMonth[0]?.totalRevenue.toFixed(2) || 0}`} 
          />
          
          <MiniStatistics 
            growth={`${((dashboardData.order.newOrdersThisMonth / dashboardData.order.newOrdersThisYear) * 100).toFixed(1)}%`} 
            name="Total Orders" 
            value={dashboardData.order.newOrdersThisYear} 
          />
          
          <MiniStatistics 
            name="Total Products" 
            value={dashboardData.product.totalProducts}
            startContent={<IconBox w="56px" h="56px" bg={boxBg} icon={<Icon w="32px" h="32px" as={MdFileCopy} textColor={brandColor} />} />}
          />
          
          <MiniStatistics 
            startContent={<IconBox w="56px" h="56px" bg="linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)" icon={<Icon w="28px" h="28px" as={MdAddTask} color="white" />} />} 
            name="New Orders This Week" 
            value={dashboardData.order.newOrdersThisWeek} 
          />
          
          <MiniStatistics 
            name="Total Users" 
            value={dashboardData.user.totalUsers} 
          />
        </SimpleGrid>

        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" my="20px">
          <TotalSpent 
            total={dashboardData.order.totalEarnings.totalEarning}
            data={dashboardData.order.revenuePerMonth.map(item => ({
              revenue: item.totalRevenue,
              month: item.label.month,
              year: item.label.year
            }))} 
          />
          <DailyTraffic userData={dashboardData.user}/>
          <DailyTraffic userData={dashboardData.user}/>
          <TotalSpent 
            total={dashboardData.order.totalEarnings.totalEarning}
            data={dashboardData.order.revenuePerMonth.map(item => ({
              revenue: item.totalRevenue,
              month: item.label.month,
              year: item.label.year
            }))} 
          />
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default Dashboard;
