import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { Heading } from "../../../components/UserComponents";
import Footer from "../../../components/UserComponents/Footer";
import Header from "../../../components/UserComponents/Header";
import ProductCard from "../../../components/UserComponents/ProductCard";
import Skeleton from "../../../components/UserComponents/Skeleton";
import { useSearchParams } from "react-router-dom";
import {
  Pagination,
  RefinementList,
  useHits,
  useInstantSearch,
  usePagination,
  useConfigure,
} from "react-instantsearch";
import PriceRangeInput from "../../../components/UserComponents/Shop/PriceRangeInput";
import SortInput from "../../../components/UserComponents/Shop/SortInput";
import env from "../../../utils/config/env";
import ClearFilters from "../../../components/UserComponents/Shop/ClearFilters";
import { transformItems } from "../../../utils";
import { X, Filter } from "lucide-react";
// import Pagination from "../../../components/UserComponents/Pagination";

export default function ShoppageDesktopPage() {
  const { setUiState } = useInstantSearch();
  const [params] = useSearchParams();
  useConfigure({ query: params.get("q") ,  hitsPerPage: 20 , maxValuesPerFacet: 20  });
  const { items } = useHits({ transformItems });
  // const {nbHits,nbPages,pages,refine,} = usePagination()

  const filteredProducts = useMemo(() => items, [items]);
  const { status } = useInstantSearch();

  const [showFilters, setShowFilters] = useState(false);

 


  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  useEffect(() => {
    const category = params.get("category[0]");
    const q = params.get("q");
    const subcategory = params.get("subcategory[0]");
    if (!q) {
      params.set("q", "");
    }
    if (category || subcategory)
      console.log(q,category,subcategory)
      setUiState((prevUiState) => {
        const { refinementList } = prevUiState[env.ALGOLIA_INDEX_NAME];
        return {
          ...prevUiState,
          [env.ALGOLIA_INDEX_NAME]: {
            ...prevUiState[env.ALGOLIA_INDEX_NAME],
            refinementList: {
              ...prevUiState[env.ALGOLIA_INDEX_NAME]?.refinementList,
              "category.name": (() => {
                const existingCategories =
                  refinementList?.["category.name"] || [];

                if (category) {
                  console.log(existingCategories)
                  // If category exists, add it to the existing categories if not already present
                  return existingCategories.includes(category)
                    ? existingCategories // Keep existing if already included
                    : [...existingCategories, category]; // Append the new category
                }
                console.log(existingCategories)

                return existingCategories; // Return existing categories if no new category
              })(),
              "subcategory.name": (() => {
                const existingSubcategories =
                  refinementList?.["subcategory.name"] || [];

                if (subcategory) {
                  return existingSubcategories.includes(subcategory)
                    ? existingSubcategories
                    : [...existingSubcategories, subcategory];
                }

                return existingSubcategories;
              })(),
            },
          },
        };
      });
  }, [params]);
  return (
    <>
      <Helmet>
        <title>
          Shop the Latest Fashion and Accessories - Elite Digital
        </title>
        <meta
          name="description"
          content="Discover the latest in men's and women's fashion, kids' toys, and accessories at Elite Digitals. Find your style with our range of products, from affordable to luxury items. Shop now for the best deals!"
        />
      </Helmet>
      <div className="flex w-full flex-col items-center gap-14 bg-white-A700 sm:gap-7">
        {/* header section */}
        <Header className="self-stretch" />

        {/* sidebar section */}
        <div className="container">
          <div className="flex justify-between items-center mb-4 w-full px-4">
            <button 
              onClick={toggleFilters} 
              className="hidden md:flex items-center gap-2 px-4 py-2 bg-gray-100 rounded-lg hover:bg-gray-200 w-full justify-center"
            >
              <Filter className="size-4" />
              Filters
              
            
            </button>
            
            {/* You might want to add sort or other controls here */}
          </div>

          <div className="flex">
          
            

           
            
                <div className={`flex px-4 flex-col gap-2 md:w-full md:fixed bottom-0 min-w-[270px] md:max-h-[80vh] overflow-auto md:shadow-[0_0_35px_4px_rgba(0,0,0,0.3)] md:bg-slate-50 md:z-10 md:p-4 ${showFilters ? 'md:translate-y-0' : 'md:translate-y-full'} md:transition-transform`}>
             
              <div className="flex flex-col items-start gap-[15px] border-b border-solid border-gray-200">
                <div className="items-center gap-2 mx-auto hidden md:flex" onClick={toggleFilters}>
                  <X className="size-4" />
                  <span className="text-sm font-semibold">Close</span>
                </div>
                <div className="md:flex w-full  gap-[15px] md:w-full mb-3">
                  <div className="mb-4 md:mb-0 md:w-[50%]">
                    <h3 className="text-lg font-bold mb-3">Categories</h3>
                    
                    <RefinementList attribute="category.name" />
                  </div>
                  <div className="mb-4 md:mb-0 md:w-[50%]">
                  <h3 className="text-lg font-bold  mb-3">Sub Categories</h3>
                  <RefinementList attribute="subcategory.name" />
                  </div>
                </div>
              </div>
          

             

              <div className=" border-b border-solid border-gray-200">
                  <PriceRangeInput />
              </div>
              <div className=" pb-[15px]">
                  <h3 className="text-lg font-bold  mb-3">Sort order</h3>
                  <SortInput />
              </div>
              <ClearFilters />
            </div>
         
            <div className="sm:mt-5 flex flex-1 flex-col items-center  md:self-stretch">
              <div className="grid w-full grid-cols-5 md:grid-cols-3 sm:grid-cols-2  place-content-center mb-10">
                {status === "loading" || status === "stalled" ? (
                  Array.from({ length: 20 }).map((_, i) => <Skeleton key={i} />)
                ) : (
                  <>
                    {filteredProducts.length ? (
                      filteredProducts.map((item) => (
                        <ProductCard
                          {...item}
                          key={item._id || item.id}
                          _id={item.id}
                          showWishlistIcon={false}
                        />
                      ))
                    ) : (
                      <h1 className="text-lg font-medium text-center text-gray-500">
                        No products found
                      </h1>
                    )}
                  </>
                )}
              </div>
              {/* pagination section */}

              <Pagination totalPages={5} padding={2} />
              {/* <Pagination /> */}

              {/* <div className="flex items-center gap-4">
                <div className="flex items-center">
                  <Img
                    src="images/rightarrow.svg"
                    alt="left arrow"
                    className="h-[26px] transform -scale-x-100 "
                  />
                  <Button
                    color="gray_200_7f"
                    size="sm"
                    shape="round"
                    className="min-w-[36px]"
                  >
                    1
                  </Button>
                  <div className="ml-[17px] flex flex-col pt-px">
                    <Text as="p">2</Text>
                  </div>
                  <div className="ml-[30px] flex flex-col pt-px">
                    <Text as="p">3</Text>
                  </div>
                  <div className="ml-[29px] flex flex-col pt-px">
                    <Text as="p">4</Text>
                  </div>
                  <div className="ml-[29px] flex flex-col pt-px">
                    <Text as="p">5</Text>
                  </div>
                </div>
                <Img
                  src="images/rightarrow.svg"
                  alt="right arrow"
                  className="h-[26px]"
                />
              </div> */}
            </div>
          </div>
        </div>
        <Footer className="self-stretch" />
      </div>
    </>
  );
}
