import React, { useEffect, useRef, useState } from "react";
import {Input} from "../Input";
import {Mail} from "lucide-react";



const NewsLetter = () => {


  return (
    <>
      {/* collections section */}
      <div className="bg-thdark">
          <div className="container mx-auto py-10 overflow-hidden">
              <div className="flex md:flex-wrap md:px-5">
                <div className="flex md:w-full md:mr-0 mr-10"> 
                  <Mail className="self-center mr-10 md:mr-auto text-slate-50 w-12 h-12 md:m-auto md:w-12 md:h-12" />
                  <div className=" self-center md:ml-4">
                      <h1 className="text-slate-50 text-3xl font-bold text-nowrap md:text-sm">GET EXCLUSIVE UPDATES AND DEALS!</h1>
                      <h5 className="text-slate-50 text-md font-normal  md:text-xs">Be the first to know about new products and exclusive promotions</h5>
                  </div>
                  </div>

                  <div className="self-center flex w-full md:mt-5">
                      <input className="text-gray-900 w-full md:pr-auto grow-1 rounded-l-[12px] bg-slate-50 px-5 outline-0" placeholder='Email' />
                      <button className="text-nowrap rounded-r-[12px] text-slate-50 bg-thlight h-[52px] font-semibold px-7">Subscribe</button>
                  </div>


              </div>

          </div>


      </div>
    </>
  );
};

export default NewsLetter;


