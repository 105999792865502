import FeedbackCard from "../../AdminComponents/Feedback/FeedbackCard.jsx";
import RatingOverview from "./RatingOverview.jsx";

const UserReviews = ({ reviews, rating }) => {

  let customeReviews = [
    {
      feedbackId : "1"  ,
      name : "John Doe",
      rating : 5,
      comment : "This is a test review",
      replies : [],
      isUser : true,
    } ,
    {
      feedbackId : "2",
      name : "Randy",
      rating : 4,
      comment : "Perfect product",
      replies : [],
      isUser : true,
    },
    {
      feedbackId : "3",
      name : "Kamel",
      rating : 3,
      comment : "Reasonable price",
      replies : [],
      isUser : true,
    }
  ]

  const customRating = {
    "1": 2,
    "2": 5,
    "3": 3,
    "4": 8,
    "5": 15,
  }

  return reviews.length > 0 ? (
    <div className="flex flex-col justify-center gap-5" id="reviews">
      <h2 className="text-3xl font-bold md:text-2xl">Reviews & Ratings </h2>
      <div className="grid grid-cols-2 md:grid-cols-1">
        <RatingOverview rating={rating} />
        <div className="border rounded-lg max-h-[500px] md:my-8 overflow-y-auto">
        {reviews.map((review, index) => (
          <FeedbackCard key={index} {...review} isUser={true} />
          ))}
        </div>
        </div>
    </div>
  ) : 
  <div className="flex flex-col justify-center gap-5" id="reviews">
  <h2 className="text-3xl font-bold md:text-2xl">Reviews & Ratings </h2>
  <div className="grid grid-cols-2 md:grid-cols-1">
    <RatingOverview rating={customRating} />
    <div className="border rounded-lg max-h-[500px] md:my-8 overflow-y-auto">
    {customeReviews.map((customeReviews, index) => (
      <FeedbackCard key={index} {...customeReviews} isUser={true} />
      ))}
    </div>
    </div>
  </div>
      
  ;
};

export default UserReviews;
