import React from "react";

const Loader = ({ className }) => {
  return (
    <div className={`flex justify-center items-center ${ className ? className : "h-screen" }`}>
      <img src="/images/loader.gif" alt="loader-elite" className="w-[150px] h-[150px]" />
    </div>
  );
};

export default Loader;
