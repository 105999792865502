import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../components/UserComponents";
import { successCheck } from "../../../assets/images";
import ConfettiExplosion from "react-confetti-explosion";
import axiosInstance from "../../../utils/api/axiosInstance";
import { USER_URL } from "../../../constants";
import { useDispatch } from "react-redux";
import { clearCart } from "../../../redux/slices/CartSlice";
const PaymentSuccess = ({ orderId, isCod = false }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isCod) {
      axiosInstance
        .post(USER_URL + `/complete-order/${orderId}?success=true`)
        .catch((e) => console.log(e.message));
    }
    dispatch(clearCart());
  }, [orderId]);
  return (
    <div className="relative max-w-md bg-white-A700 p-6  mx-auto shadow-lg rounded-[16px] mt-5">
      <ConfettiExplosion duration={3000} width={2000} particleCount={200} />
      <div className="flex justify-center pb-2">
        <img src={successCheck} alt="Order placed" className="size-[100px]" />
      </div>
      <div className="text-center">
        <h3 className="md:text-2xl text-4xl font-bold text-center my-5">
          Order Placed!
        </h3>
        <p className="text-gray-600 my-2 font-semibold">Thank you for purchasing with us. <br/> Have a great day!</p>
        <div className="flex items-center justify-center gap-2 py-5 text-center">
          <button
            className="px-4 py-2 bg-thdark text-white-A700 font-medium rounded-[26px]"
            onClick={() => navigate("/shop")}
          >
            Continue Shopping
          </button>
          <button
            className="px-4 py-2 text-thdark bg-white-A700  rounded-[26px] border-solid border-2 border-thdark font-medium"
            onClick={() => navigate("/orders")}
          >
            Track order
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
