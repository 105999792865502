import React from "react";

const Skeleton = ({className}) => {
    return (
        <div className={`max-w-full rounded-[16px] mx-2 overflow-hidden shadow-md animate-pulse mb-4 ${className}`}>
            <div className="flex items-center justify-center h-[260px] sm:h-[200px] rounded-[16px] bg-gray-200">
                <img  src="/images/icon-white.png" alt="icon-loader" className="w-[120px] h-[120px] opacity-60" />
            </div>
            <div className="p-4 md:p-2">
                <div className="h-3 w-10 bg-gray-200 rounded-full dark:bg-gray-700 md:w-full mb-6 mt-2" />
                <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 mb-6" />
                <div className="h-4 w-40 bg-gray-200 rounded-full dark:bg-gray-700 mb-3" />
            </div>

        </div>
    );
};

export default Skeleton;
