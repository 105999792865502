import React, { useEffect, useMemo, useRef, useState } from "react";
import { Heading } from "../Heading";
import { Slider } from "../Slider";
import ProductCard from "../ProductCard";
import { useConfigure, useHits } from "react-instantsearch";
import Skeleton from "../Skeleton";
import { useSelector } from "react-redux";
import { getProductsWithWishlistStatus } from "../../../utils/api/products";
import QuickViewProduct from "../ProductCard/quickviewproduct";
import { Link } from "react-router-dom";

const DealShowCase = () => {
  const [sliderState, setSliderState] = useState(0);
  const [newArrivals, setNewArrivals] = useState([]);
  const sliderRef = useRef(null);
  useConfigure({
    hitsPerPage: 6,
  });
  const { items } = useHits();
  const { user } = useSelector((state) => state.user);

  const [quickViewOpen, setQuickViewOpen] = React.useState(false);
  const [quickViewId , setQuickViewId] = React.useState(1);


  useEffect(() => {
    console.log(items);
    async function updateProductWithwishlist() {
      // updates the products list with wishlist status when user is logged in
      if (user.isAuthenticated && user.role !== "admin") {
         const updatedProductList = await getProductsWithWishlistStatus(items);
        setNewArrivals(updatedProductList);
        return; 
      }
      setNewArrivals(items);
    }
    updateProductWithwishlist();
  }, [items, user]);

  function checkQwModalState(state , id) {
    setQuickViewOpen(state);
    setQuickViewId(id)
  }
  return (

    <div className="container mt-10 flex flex-col items-start gap-[29px] md:p-5 rounded-[16px] p-10 shadow-xl">
      <div className="flex w-full justify-between">
      <Heading size="mdx" as="h1">
        <span className="text-gray-900">Best in </span>
        <span className="thlight"> Furnitures</span>
      </Heading>

    {/*   <Link to="/shop?q=&category[0]=Furniture&page=1">More</Link> */}
        
      </div>

     
      <div className="grid grid-cols-2 md:grid-cols-1 w-full gap-8">
        <div className="cursor-pointer h-[280px] md:h-auto overflow-hidden rounded-[16px] shadow-lg">
          <Link to="/shop?q=&category[0]=Furniture&page=1">
            <img className="w-full hover:scale-110 transition-all duration-300" src="/images/furniture-banner-1.png" alt="furniture-1" />
          </Link>
          
        </div>
        <div className="cursor-pointer h-[280px] md:h-auto overflow-hidden rounded-[16px] shadow-lg">
          <Link to="/shop?q=&category[0]=Furniture&page=1">
          <img className="w-full hover:scale-110 transition-all duration-300" src="/images/furniture-banner-2.png" alt="furniture-2" />
          </Link>
        </div>
      </div>
      

      {!newArrivals.length ? (
        <div className="grid grid-cols-6 md:grid-cols-3 sm:grid-cols-1 gap-6 mx-2.5">
          {Array.from({ length: 6 }).map((_, i) => (
            <Skeleton key={i} />
          ))}
        </div>
      ) : null}
      <div className="flex flex-col items-center gap-16 self-stretch sm:gap-8 mt-5">
        <div className="mx-2.5 grid grid-cols-6 md:grid-cols-2 w-full max-w-[1440px] md:mx-0">
        {newArrivals.slice(0,6).map((item) => (
              <ProductCard {...item} key={item._id} _id={item.id} {...item} showQuick={true} qwModalOpenState={checkQwModalState} />
            ))}
        </div>
      </div>
      {
        quickViewOpen ?
            <QuickViewProduct pid={quickViewId} key={quickViewId} closeHandle={()=> setQuickViewOpen(false)}/>
            : <></>
      }
    </div>
  );
};

export default DealShowCase;
