import React, { useCallback, useEffect, useState } from "react";
import Header from "../../../components/UserComponents/Header";
import Footer from "../../../components/UserComponents/Footer";
import { USER_URL } from "../../../constants";
import OrderList from "../../../components/UserComponents/orders/OrderList";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../utils/api/axiosInstance";
import showToast from "../../../utils/showToast";
import Loader from "../../../components/UserComponents/Loader";
import {ChevronLeft} from "lucide-react";

const OrdersPage = () => {
    const [orders, setOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    
    useEffect(() => {
        setIsLoading(true);
        axiosInstance(USER_URL + "/orders").then(({ data }) => setOrders(data?.data)).catch((e) => showToast(e.message, "error")).finally(() => setIsLoading(false));
    }, []);

    return (
        <>
            <Header />
            <div className="container mx-auto justify-center items-center min-h-[500px] mb-40">
                <div className="w-3/4 md:w-full mx-auto px-2">
                    <nav className="flex items-center text-sm my-6">
                        <a href="/" className="text-gray-500 hover:text-gray-700">Home</a>
                        <ChevronLeft className="mx-2 h-4 w-4 text-gray-400" />
                        <span className="text-gray-900 font-medium">Orders</span>
                    </nav>
                    <h1 className="text-3xl font-bold md:text-2xl mb-5">Manage your orders</h1>
                    {orders?.length ? (

                        <div className="space-y-3">
                            {orders.map((order) => (
                                <div className="space-y-3" key={order?._id}>
                                    {order?.products.map((product) => (
                                        <OrderList {...product} key={product._id} orderId={order._id} />
                                    ))}
                                </div>
                            ))}
                        </div>
                    ) : isLoading ? (
                        <>
                            <ListSkeleton />
                            <ListSkeleton />
                            <ListSkeleton />
                        </>

                    ) : (
                        <h3 className="py-5 font-medium text-lg px-3 text-gray-400">No orders found</h3>
                    )}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default OrdersPage;


const ListSkeleton = () => {
    return (
        <div className="w-full flex overflow-hidden animate-pulse my-3 md:my-3 border border-gray-200 rounded-lg  p-4">
            <div className="rounded-lg h-[96px] w-[96px] mr-auto bg-gray-300 dark:bg-gray-700">
                <img  src="/images/icon-white.png" alt="icon-loader" className="max-w-full  max-h-full opacity-60" />
            </div>

                <div className=" mx-4 my-2 flex flex-col justify-between grow self-center h-[90px]">
                    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-3/4 mr-auto" />
                    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-2/4 mr-auto mb-auto mt-4" />
                    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-2/4 mr-auto my-auto" />
                    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-2/4 mr-auto my-auto" />
                </div>


        </div>
    );
};
