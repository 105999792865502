import React, { useRef, useState, useEffect } from "react";
import { Text, Img } from "./..";
import { useNavigate } from "react-router-dom";
import ToggleSwitch from "../ToggleSwitch";
import useOutsideAlerter from "../../../Hooks/OutsideClickAlerter";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../utils";
import CategoryMenu from "./CategoryMenu";
import showToast from "../../../utils/showToast";
import "instantsearch.css/themes/satellite.css";
import SearchBox from "./SearchBox";
import { toggleUserType } from "../../../redux/slices/UserSlice";
import { useSearchBox } from "react-instantsearch";
import CategoryStrip from "./CategoryStrip";
import { AlignJustify, Heart, LogOut, ShoppingCart, User, X , Menu } from "lucide-react";

export default function Header({ ...props }) {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { cart } = useSelector((state) => state.cart);
  const { user } = useSelector((state) => state.user);
  const { wishlist } = useSelector((state) => state.wishlist);
  const { refine } = useSearchBox();
  const [showIcons, setShowIcons] = useState(false);

  useOutsideAlerter(dropdownRef, () => setIsDropdownVisible(false));

  useEffect(() => {
    const handleScroll = () => {
      setShowIcons(window.scrollY > 100); // Show after scrolling 100px
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMode = () => {
    const B2BStatus = !user?.isB2B;
    dispatch(toggleUserType(B2BStatus));
    if (B2BStatus) {
      document.body.style.opacity = 0.5;
      setTimeout(() => {
        document.body.style.opacity = 1;
      }, 1000);
    }
    refine("");
  };

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  }
  const handleLogout = () => {
    logout();
    navigate("/");
    showToast("Logged out successfully");
  };

  return (
      <header {...props}>
        <div className="bg-thdark text-slate-100 w-full fixed top-0 z-[99]">
          <div className="lg:mx-10 mx-2">
            <div className="flex py-3 md:py-2 ">
              <div className="md:mr-3">
                <h1 className="text-xl md:text-xs font-bold md:font-normal self-center">Let us build the perfect e-commerce solution for your brand. Reach out today!</h1>
                <p className="text-sm md:text-xs font-light md:hidden">Looking to integrate this page for better engagement? We can set it up in no time.</p>
              </div>
              <Link to="/contact-us" className="self-center ms-auto text-sm md:text-xs  text-nowrap bg-thdark border-slate-100 border-[2px] px-8 py-2 rounded-2xl">Contact Us </Link>
            </div>
          </div>

          {
            user?.isAuthenticated && (
              <div className={`fixed top-20 right-4 z-50 bg-slate-50 sm:hidden bg-white rounded-full shadow-md p-2 px-5 transition-all duration-300 ${
                showIcons ? 'translate-y-0 opacity-100' : '-translate-y-full opacity-0'
              }`}>
                <div className="flex justify-center items-center gap-3">
                  <a onClick={(e) => { e.preventDefault(); navigate("/wishlist");}} className="relative hover:opacity-80 transition-opacity">
                    <Heart className="m-auto h-[24px] w-[24px] cursor-pointer text-black" />
                    {
                      wishlist?.length > 0 &&
                      <Text size="xs" as="p" className="absolute -top-2 -right-2 m-auto flex h-[20px] w-[20px] items-center justify-center rounded-[10px] bg-slate-50 text-center !text-thligth border !border-gray-200">
                        {wishlist?.length}
                      </Text>
                    }                   
                  </a>

                  <a onClick={(e) => { e.preventDefault(); navigate("/cart");}} className="relative hover:opacity-80 transition-opacity">
                    <ShoppingCart src="/images/cart.svg" className="m-auto h-[24px] w-[24px] cursor-pointer text-black"/>
                    {
                      cart?.length > 0 && 
                      <Text size="xs" as="p" className="absolute -top-2 -right-2 m-auto flex h-[20px] w-[20px] items-center justify-center rounded-[10px] bg-light_blue-600 text-center !text-white-A700">
                        {cart?.length}
                      </Text>
                    }
                    
                  </a>
                </div>
              </div>
            )
          }
        </div>
        <div  className={`${props.className} flex  justify-center items-center border-gray-200 border-b border-solid bg-white-A700 mt-[72px] md:mt-[52px] md:pt-0` }>
          <div className="w-full md:flex-wrap flex items-center justify-between gap-3 md:py-2 md:px-4 px-10">
            <div className="flex gap-2 items-center order-1">
              <Img src="/images/company-logo.png" alt="elite-digital" className="h-[100px] w-[250px] object-contain cursor-pointer md:hidden" onClick={() => navigate("/", { replace: true })} />
              <Img src="/images/elite-ico-mobile.svg" alt="elite-digital" className="h-[70px] w-[70px] object-contain cursor-pointer md:block hidden p-3" onClick={() => navigate("/", { replace: true })} />
            </div>

            <div className="flex order-3 md:order-2">
              <a href="https://elitedigitals.ae/" target="_blank" className="md:text-xs md:text-center bg-gradient-to-r md:w-[50%] from-thdark to-thlight text-slate-50 border-[2px] border-thlight px-6 md:px-4 py-2  font-medium rounded-2xl text-nowrap cursor-pointer">Ai Features</a>
              <a href="https://elitedigitals.ae/" target="_blank" className="md:text-xs md:text-center ms-2 md:w-[50%] bg-slate-50 border-thdark border-[2px] hover:text-thlight hover:border-thlight text-thdark px-6 md:px-4 py-2  font-medium rounded-2xl text-nowrap cursor-pointer">Our Ecosystem</a>
            </div>

            <SearchBox   />
      
           <div className="order-4 md:order-2 hidden md:flex items-center justify-end gap-[22px]" onClick={toggleMenu}>
            <Menu className="h-[24px] w-[24px] self-center cursor-pointer"   />
           </div>
          

           {showMenu && (
            <div className="w-full bg-white-A700 md:order-2">
            <ul className="py-1 flex justify-between w-full">
            {user?.isAuthenticated ? (
              <>
              <li><Link to="/profile" className="block py-2 text-gray-800 hover:bg-gray-100 font-semibold text-sm"> <User  className="h-[20px] w-[20px] mx-auto cursor-pointer"/>Account</Link></li>
              <li><Link to="/wishlist" className="block py-2 text-gray-800 hover:bg-gray-100 font-semibold text-sm"><Heart  className="h-[20px] w-[20px] mx-auto cursor-pointer"/> Wishlist</Link></li>
              <li><Link to="/cart" className="block py-2 text-gray-800 hover:bg-gray-100 font-semibold text-sm"> <ShoppingCart src="/images/cart.svg" className="m-auto h-[20px] w-[20px] cursor-pointer"/> Cart({cart?.length})</Link></li>
              <li><span onClick={handleLogout} className="block py-2 text-gray-800 hover:bg-gray-100 font-semibold text-sm" > <LogOut  className="m-auto h-[20px] w-[20px] cursor-pointer"/> Logout</span></li>
              <li onClick={toggleMenu} className="self-center"><X className="size-6" /></li>
              </>
            ) : (
            <>
            <li className="w-full"><Link to="/login" className="block py-2 text-gray-800 hover:bg-gray-100 font-semibold text-lg text-center">Login</Link></li>
            </>)}
            </ul>
          </div>
           )}


            <div className="order-4 md:order-2 md:hidden flex items-center justify-end gap-[22px]">
              {user?.isAuthenticated ? (
                  <>
                    <div className="relative">

                      <User  className="h-[24px] w-[24px] self-center cursor-pointer" onClick={toggleDropdown} />

                      {isDropdownVisible && (
                          <div ref={dropdownRef} className="absolute top-10 right-0  mt-2 w-48 bg-white-A700 border border-gray-200 rounded-md shadow-lg z-50">
                            <ul className="py-1">
                              <li className="block px-4 py-2 text-gray-800 hover:bg-gray-100 break-words border-b font-semibold text-sm text-ellipsis whitespace-nowrap overflow-hidden">Hey, <br/> {user?.name || "User"}</li>
                              <li><Link to="/profile" className="block px-4 py-2 text-gray-800 hover:bg-gray-100 font-semibold text-sm">Account</Link></li>
                              <li><Link to="/orders" className="block py-2 px-4 text-gray-800 hover:bg-gray-100 font-semibold text-sm">Orders</Link></li>
                              <li><Link to="/address" className="block py-2 px-4 text-gray-800 hover:bg-gray-100 font-semibold text-sm">Manage Address</Link></li>
                              <li>
                                <button to="/addresses" className="inline-flex w-full items-center gap-2 px-4 py-2 text-gray-800 hover:bg-gray-100 font-semibold text-sm" onClick={handleLogout}>
                                  Logout <LogOut className="size-4 text-red-500" />
                                </button>
                              </li>
                            </ul>
                          </div>
                      )}
                    </div>

                    <a onClick={(e) => { e.preventDefault(); navigate("/wishlist");}} className="relative">
                      <Heart className="m-auto h-[24px] w-[24px] cursor-pointer" />
                      {
                        wishlist?.length > 0 && 
                        <Text size="xs" as="p" className="absolute -top-2 -right-2  m-auto flex h-[19px] w-[19px] items-center justify-center rounded-[10px] bg-slate-50 text-center !text-thligth border !border-gray-200">
                          {wishlist?.length}
                        </Text>
                      }
                     
                    </a>


                    <a onClick={(e) => { e.preventDefault(); navigate("/cart");}} className="relative">
                      <ShoppingCart src="/images/cart.svg" className="m-auto h-[24px] w-[24px] cursor-pointer"/>
                      {
                        cart?.length > 0 &&
                        <Text size="xs" as="p" className="absolute -top-2 -right-2  m-auto flex h-[20px] w-[20px] items-center justify-center rounded-[10px] bg-light_blue-600 text-center !text-white-A700">
                          {cart?.length}
                        </Text>
                      }
                      
                    </a>



                  </>
              ) : (
                  <button
                      onClick={() => navigate("/login")}
                      className="bg-thdark hover:bg-thlight text-white-A700 px-6 py-2 font-medium rounded-2xl "
                  >
                    Login
                  </button>
              )}
            </div>
            
          </div>

        </div>
      </header>
  );
}
