import React, { useMemo } from "react";
import convertTodateString, {
  getDay_month_yearFormated,
} from "../../../utils/convertTodateString";
import { getLastOrderStatus } from "../../../utils";
import { darkColor } from "../../../constants";
import { useParams } from 'react-router-dom';

const OrderRow = ({
  orderId,
  totalAmount,
  paymentMethod,
  createdAt,
  products,
  handleClick,
  _id,
  sNo,
}) => {
  const { orderId: selectedOrderId } = useParams();

  const [deliveredItemsCount, totalItems] = useMemo(() => {
    let deliveredCount = 0;
    products.forEach(
      (item) =>
        getLastOrderStatus(item.status).name === "delivered" && deliveredCount++
    );
    return [deliveredCount, products.length];
  }, []);
  return (
    <div onClick={handleClick} className={`border-b dark:border-gray-800 hover:cursor-pointer p-3 dark:bg-dark flex ${
      selectedOrderId === _id 
        ? 'bg-blue-50 dark:bg-blue-900/20' 
        : 'bg-white-A700'
    }`}>

      <div className="me-auto">
        <div className="pt-2 px-4 text-md font-semibold">{orderId}</div>
        <div className="px-4 text-sm">{getDay_month_yearFormated(createdAt)}</div>
        <div className="px-4 text-sm text-gray-500">{`${deliveredItemsCount}/${totalItems} Delivered`}</div>
      </div>

      <div className="py-2">
        <div className="px-4 text-2xl font-bold text-right">$ {totalAmount}</div>
        <div className="px-4 text-xs text-right">{paymentMethod.toUpperCase()}</div>
      </div>
      
      
     
    </div>
  );
};

export default OrderRow;
