import React, { useEffect } from "react";
import OrderRow from "./OrderRow";
import Loader from "../../UserComponents/Loader";
import { ADMIN_URL } from "../../../constants";
import { useNavigate } from "react-router-dom";
import useFetchApi from "../../../Hooks/useFetchApi";
import { useState } from "react";


const OrdersList = () => {

  const { data: orders, isLoading, setData,} = useFetchApi({ url: ADMIN_URL + "/orders" });
  const [isLoadingUI, setIsLoadingUI] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoadingUI(false);
    if (orders?.length) {navigate(`/admin/orders/${orders[0]._id}`); setIsLoadingUI(true);};
   
  }, [orders]);

  return (
    <div className="col-span-1">
      {
        isLoadingUI? 
        <div className="w-full">
        <div className="overflow-x-auto border dark:border-gray-800 bg-white-A700 rounded-lg h-[750px] overflow-y-auto dark:bg-dark-card">
          {orders.length ? (
                <>
                  {orders.map((order, i) => (
                    <OrderRow
                      {...order}
                      key={order._id}
                      sNo={i + 1}
                      handleClick={() => navigate(`/admin/orders/${order._id}`)}
                    />
                  ))}
                </>
              ) : (
                <div>
                  <div className="text-lg font-medium ">No orders found</div>
                </div>
              )}
        </div>
    </div> : <></>
      }

    </div>
  );
};

export default OrdersList;