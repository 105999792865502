import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Heading } from "../UserComponents/Heading";
import Navigation from "../UserComponents/Navigation";

const NotFoundPage = () => {
  const navigate = useNavigate();
  
  return (
    <div className="min-h-screen bg-white font-poppins">
      {/* Header Section */}
      <header className="container mx-auto px-4 py-1 flex justify-between items-center">
        <div className="w-[20%]">
          <img 
            src="/images/company-logo.png" 
            alt="Company Logo" 
            className="w-full"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0xMiAwYzYuNjIzIDAgMTIgNS4zNzcgMTIgMTJzLTUuMzc3IDEyLTEyIDEyLTEyLTUuMzc3LTEyLTEyIDUuMzc3LTEyIDEyLTEyem0wIDFjNi4wNzEgMCAxMSA0LjkyOSAxMSAxMXMtNC45MjkgMTEtMTEgMTEtMTEtNC45MjktMTEtMTEgNC45MjktMTEgMTEtMTF6bS0uMDUgMTcuMDFjLjU1MiAwIDEgLjQ0OCAxIC45OTlzLS40NDggMS0xIDEtMS0uNDQ5LTEtMWMwLS41NTEuNDQ4LS45OTkgMS0uOTk5em0wLTEyLjAyYy41NTIgMCAxIC40NDggMSAxdjcuMDJjMCAuNTUxLS40NDggMS0xIDFzLTEtLjQ0OS0xLTF2LTcuMDJjMC0uNTUyLjQ0OC0xIDEtMXoiLz48L3N2Zz4=";
            }}
          />
        </div>
        
        <Navigation />
      </header>

      {/* 404 Content Section */}
      <main 
        className="relative min-h-[calc(100vh-60px)] bg-cover bg-center bg-no-repeat -mt-6"
        style={{ 
          backgroundImage: 'url("/images/404-character.png")',
        }}
      >
        <div className="container mx-auto px-4 h-full">
          <div className="absolute right-[15%] top-1/2 transform -translate-y-1/2 max-w-[50%]">
            <Heading 
              as="h1" 
              size="xl" 
              className="text-[72px] font-black tracking-tight leading-none mb-8"
            >
              AWWW...DON'T CRY.
            </Heading>
            
            <div className="space-y-4">
              <p className="text-[28px] font-bold text-gray-800">
                It's just a 404 Error!
              </p>
              <p className="text-[20px] text-gray-600 font-medium">
                What you're looking for may have been misplaced in Long Term Memory.
              </p>
              <div className="flex gap-4 mt-8">
                <Link 
                  to="/" 
                  className="bg-thdark px-10 text-xl font-bold rounded-full text-slate-50 py-5 flex md:text-sm md:py-2 ms:px-3 hover:opacity-90 transition duration-300"
                >
                  Go Home
                </Link>
                <button 
                  onClick={() => navigate(-1)} 
                  className="px-10 py-5 border-2 text-xl font-bold rounded-full border-thdark text-thdark md:text-sm md:py-2 ms:px-3 hover:bg-gray-50 transition duration-300"
                >
                  Go Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default NotFoundPage;
