import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  wishlist: [],
};

export const WishListSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {
    loadWishListFromServer: (state, { payload }) => {
      state.wishlist = payload;
    },
    addToWishList: (state, { payload }) => {
      state.wishlist = [...state.wishlist, payload];
    },
    removeFromWishList: (state, { payload }) => {
      console.log(payload)
      state.wishlist = state?.wishlist.filter((product) => product._id !== payload._id);
    },
    clearWishList: () => initialState,
  },
});

export const {
  loadWishListFromServer,
  addToWishList,
  removeFromWishList,
  clearWishList,
} = WishListSlice.actions;

export default WishListSlice.reducer;
