import React, { useState } from "react";
import { Text, Input, Button } from "../../../components/UserComponents";
import Header from "../../../components/UserComponents/Header";
import Footer from "../../../components/UserComponents/Footer";
import { postContactUs } from "../../../utils/api/contactus";
import showToast from "../../../utils/showToast";
import { LoaderCircleIcon, Mail, MapPin, Phone } from "lucide-react";
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Box } from "@chakra-ui/react";
import { LoaderCircle } from "lucide-react";

const ContactUsPage = () => {
  const [formData, setFormData] = useState({ name: "", email: "", message: "" });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    if (!e.target) return;
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    postContactUs(formData.name, formData.email, formData.message).then(() => {
      showToast("Message sent successfully");
      setIsSubmitting(false);
      setFormData({ name: "", email: "", message: "" });
    }).catch((e) => {
      showToast(e.message, "error")});
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow">
        <div className="container mx-auto py-16 md:py-2">
          <div className="w-full mx-auto p-8 md:p-3 rounded-lg">

            <div className="grid grid-cols-2 md:grid-cols-1">
              <div className="col-span-2">
                <div className="text-center mb-12 md:mb-6">
                  <Text className="text-gray-600 mb-2 md:mb-1" size="lg" as="p">Get in Touch</Text>
                  <h2 className="text-5xl font-bold mb-4 md:text-3xl md:mb-1">Contact Us</h2>
                  <Text className="text-gray-600" as="p">Have questions? We're here to help!</Text>
                </div>
              </div>
              <div className="col-span-2 grid grid-cols-3 md:grid-cols-2 mt-10 mb-20 bg-gray-100 rounded-lg">
                <div className="text-center my-10">
                  <Mail className="mx-auto" height={50} width={50} color="#146d95"/>
                  <h2 className="text-2xl font-bold mt-4 md:mb-1 ">Email</h2>
                  <p className="text-sm  mb-4 md:mb-1">ecom@elitedigitals.ae</p>
                </div>
                <div className="text-center my-10">
                  <Phone className="mx-auto" height={50} width={50} color="#146d95"/>
                  <h2 className="text-2xl font-bold mt-4 md:mb-1">Phone</h2>
                  <p className="text-sm  mb-4 md:mb-1">+971 54 756 4435</p>
                </div>
                <div className="text-center my-10 md:col-span-3">
                  <MapPin className="mx-auto" height={50} width={50} color="#146d95"/>
                  <h2 className="text-2xl font-bold mt-4 md:mb-1">Address</h2>
                  <p className="text-sm  mb-4 md:mb-1">Moosa Tower 2, Sheikh Zayed Road, Dubai</p>
                </div>
              </div>

              <div className="mx-10 md:mx-2 md:col-span-2 mb-20">
              <h2 className="text-4xl font-bold mb-10 md:text-3xl md:mb-1">FAQ</h2>
              <Accordion allowMultiple>
                <AccordionItem>
                <h2>
                  <AccordionButton padding={10}>
                    <Box flex="1" textAlign="left" className="font-semibold"> 1.⁠ ⁠What features are included in the basic package?</Box>
                    <AccordionIcon height={25} width={25} />
                  </AccordionButton>
                </h2>
                <AccordionPanel padding={10} className="text-sm mb-10">The basic package includes a complete website, admin dashboard, product and order management, and payment gateway integration.</AccordionPanel>
                </AccordionItem>
              </Accordion>

              <Accordion allowMultiple>
                <AccordionItem>
                <h2>
                  <AccordionButton padding={10}>
                    <Box flex="1" textAlign="left" className="font-semibold"> 2.⁠ ⁠How fast can you launch the platform for my business?</Box>
                    <AccordionIcon height={25} width={25} />
                  </AccordionButton>
                </h2>
                <AccordionPanel padding={10} className="text-sm mb-10">The setup takes about 7-10 working days, depending on customization needs.</AccordionPanel>
                </AccordionItem>
              </Accordion>

              <Accordion allowMultiple>
                <AccordionItem>
                <h2>
                  <AccordionButton padding={10}>
                    <Box flex="1" textAlign="left" className="font-semibold">3. ⁠What AI features does the platform offer?</Box>
                    <AccordionIcon height={25} width={25} />
                  </AccordionButton>
                </h2>
                <AccordionPanel padding={10} className="text-sm mb-10">Current features include enhanced product search. Future updates will add AI chat support and customer insights.</AccordionPanel>
                </AccordionItem>
              </Accordion>

              <Accordion allowMultiple>
                <AccordionItem>
                <h2>
                  <AccordionButton padding={10}>
                    <Box flex="1" textAlign="left" className="font-semibold">4.⁠ ⁠Can I manage everything from one place, including the mobile app?</Box>
                    <AccordionIcon height={25} width={25} />
                  </AccordionButton>
                </h2>
                <AccordionPanel padding={10} className="text-sm mb-10">Yes, the admin dashboard controls both the website and mobile app for seamless management.</AccordionPanel>
                </AccordionItem>
              </Accordion>

              <Accordion allowMultiple>
                <AccordionItem>
                <h2>
                  <AccordionButton padding={10}>
                    <Box flex="1" textAlign="left" className="font-semibold">5.⁠ ⁠What kind of support do you provide after launch?</Box>
                    <AccordionIcon height={25} width={25} />
                  </AccordionButton>
                </h2>
                <AccordionPanel padding={10} className="text-sm mb-10">We offer three months of free support, with options for extended plans and additional feature requests.</AccordionPanel>
                </AccordionItem>
              </Accordion>

              <Accordion allowMultiple>
                <AccordionItem>
                <h2>
                  <AccordionButton padding={10}>
                    <Box flex="1" textAlign="left" className="font-semibold">6.⁠ ⁠Is the platform scalable if my business grows?</Box>
                    <AccordionIcon height={25} width={25} />
                  </AccordionButton>
                </h2>
                <AccordionPanel padding={10} className="text-sm mb-10">Yes, it’s built to scale with options like multi-vendor support and advanced analytics.</AccordionPanel>
                </AccordionItem>
              </Accordion>

            </div>

            <form onSubmit={handleSubmit} className="space-y-6 mb-20 md:mx-2 mx-10">
            <h2 className="text-4xl font-bold mb-10 md:text-3xl md:mb-1">Send us a message</h2>
              <div className="grid grid-cols-2 md:grid-cols-1 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Name</label>
                  <input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Your name" required className="w-full !shadow-none !border-none !bg-gray-100 h-[50px] !rounded-md !px-3" />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Email</label>
                  <input type="email" name="email" value={formData.email} onChange={handleChange} placeholder="your@email.com" required className="w-full !shadow-none !border-none !bg-gray-100 h-[50px] !rounded-md !px-3" />
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Message</label>
                <textarea name="message" value={formData.message} onChange={handleChange} placeholder="Your message" required rows={4} className="w-full px-3 py-2 rounded-md focus:outline-none !shadow-none !border-none !bg-gray-100" />
              </div>
              <div>
                <button disabled={isSubmitting} type="submit" className="w-1/2 flex mx-auto justify-center md:w-full bg-thdark text-slate-50 px-8 py-2 rounded-lg text-lg font-bold">
                {isSubmitting && <LoaderCircleIcon spin={true} className="animate-spin absolute mr-[210px]" />}
                Send Message
                </button>
              </div>
            </form>




            <div>
         
              </div>
            </div>

   

          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default ContactUsPage;

