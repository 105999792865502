import React from "react";
import { useClearRefinements } from "react-instantsearch";
import { Button } from "../Button";

const ClearFilters = () => {
  const { canRefine, refine } = useClearRefinements({
    includedAttributes: ["category.name", "subcategory.name", "price"],
  });

  return (
    <button
      shape="round"
      color="gray_900"
      className="font-medium text-sm bg-thdark text-slate-50 px-4 py-2 rounded-md"
      disabled={!canRefine}
      onClick={refine}
    >
      Clear Filters
    </button>
  );
};

export default ClearFilters;
