import React from "react";
import { Link } from "react-router-dom";

const Navigation = () => {
  return (
    <nav className="flex space-x-8">
      <Link to="/" className="text-gray-900 hover:text-gray-600">HOME</Link>
      <Link to="/shop" className="text-gray-900 hover:text-gray-600">STORE</Link>
      <Link to="/cart" className="text-gray-900 hover:text-gray-600">CART</Link>
      <Link to="/profile" className="text-gray-900 hover:text-gray-600">PROFILE</Link>
      <Link to="login" className="text-gray-900 hover:text-gray-600">LOGIN</Link>
      <Link to="logout" className="text-gray-900 hover:text-gray-600">LOGOUT</Link>
    </nav>
  );
};

export default Navigation; 