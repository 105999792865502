import React, { useEffect, useRef, useState } from "react";
import { Heading, Slider } from "..";
import useFetchApi from "../../../Hooks/useFetchApi";
import { USER_URL } from "../../../constants";
import { useNavigate } from "react-router-dom";
import Skeleton from "../Skeleton";

const CategoryBanners = () => {
  const [sliderState, setSliderState] = useState(0);
  const { data } = useFetchApi({ url: USER_URL + "/categories" });
  const [pages , updatePages] = useState(0);
  const sliderRef = useRef(null);

  useEffect(() => {
      updatePages(Math.ceil(data.length / 6))
  })

  return (
    <>
      {/* collections section */}
      <div className="container mx-auto mt-10 flex flex-col items-center gap-[34px] md:gap-3 md:p-5 md:mt-0">
          <h1  className="font-bold text-5xl md:text-2xl text-center w-full my-8 md:my-2">
            Shop By Category
          </h1>
        <div className="flex flex-col items-center gap-16 self-stretch sm:gap-8">
          <div className="mx-auto flex w-full max-w-[1440px] gap-3 self-stretch md:mx-0 md:flex-col">
              {
                  data.length > 0 &&
                  <Slider
                      autoPlay
                      autoPlayInterval={4000}
                      responsive={{
                          0: { items: 4 },
                          550: { items: 6 },
                          1050: { items: 9 },
                      }}
                      renderDotsItem={(props) => {
                          return props?.isActive ? (
                              <div className="mr-1.5 h-[6px] w-[6px] rounded-[3px] bg-gray_900_cc" />
                          ) : (
                              <div className="mr-1.5 h-[6px] w-[6px] rounded-[3px] bg-gray_900_66" />
                          );
                      }}
                      activeIndex={sliderState}
                      onSlideChanged={(e) => {
                          setSliderState(e?.item);
                      }}
                      ref={sliderRef}
                      items={data.map((category) => (
                          <CategoryCard {...category} key={category._id} />
                      ))}
                  />
              }
              {
                  data.length === 0 &&

                  <Slider
                  autoPlay={false}
                  responsive={{
                      0: { items: 4 },
                      550: { items: 6 },
                      1050: { items: 9 },
                  }}
                  renderDotsItem={(props) => {
                      return props?.isActive ? (
                          <div className="mr-1.5 h-[6px] w-[6px] rounded-[3px] bg-gray_900_cc" />
                      ) : (
                          <div className="mr-1.5 h-[6px] w-[6px] rounded-[3px] bg-gray_900_66" />
                      );
                  }}
                  activeIndex={sliderState}
                  onSlideChanged={(e) => {
                      setSliderState(e?.item);
                  }}
                  ref={sliderRef}
                  items={  Array.from({ length: 9 }).map((_, i) => <CategorySkeleton key={i}/> )}
              />

                  


              }
              
          </div>

          
        </div>
      </div>
    </>
  );
};

export default CategoryBanners;

const CategoryCard = ({ name, url, image }) => {
  const navigate = useNavigate();
  return (
    <div className="max-w-[250px] rounded-xl space-y-2  bg-white-A700 overflow-y-hidden" >
      <div className="flex items-center rounded-full h-[130px] w-[130px] md:h-[70px] md:w-[70px] mb-2 mx-auto bg-gray-100 overflow-hidden shadow-lg">
        <img
          src={
            image ||
            "https://s3-alpha-sig.figma.com/img/d157/5026/b4a19949b8a5a72c8a3ec64702a26b54?Expires=1729468800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Nrn4MZuQqruGS1sZPRxeU3Rwn4Ra6EFxeA-Z6ofwQ1rVYdzC9-hMex8XcOZU9Crph-xU7svTNDfq45JuYqsCXOh~9lunVUeSGzXlgZU-SWtRfEFki1Jv~-cE33yRyiO86t6zMc0IxsrShz-vFZuzGMdAtXwXXT6ttLsEgFYascqUaKD7AYbsGXHLSNr2d66PF-mggPWKHIcwzbEuSQw84R0l-tiMKgGgKMfFgx557URLZ1E~zrfbgUsLrzrRqFSpeouSzIT9hb3JqyAeun3gPgCYnLrWyWbBaMoTLBmc~fLjG6Djl3Ki2L7he~SDihpCSWvDuw9RfS2iE~IKE3bJ3g__"
          }
          alt={`Cateory-${name}`}
          className="w-full h-full object-cover cursor-pointer"
          onClick={() => navigate(`/shop?q=&category[0]=${name}`)}
        />
      </div>

      <div className="flex flex-col items-center justify-center">
        <h2 className="text-xl font-semibold cursor-pointer md:text-sm sm:text-xs" onClick={() => navigate(`/shop?q=&category[0]=${name}`)}>{name}</h2>
      </div>
    </div>
  );
};


const CategorySkeleton = () => {
    return (
        <div className="max-w-full overflow-hidden animate-pulse border-none">
            <div className="flex items-center justify-center rounded-full h-[130px] w-[130px] md:h-[70px] md:w-[70px] mx-auto bg-gray-300 dark:bg-gray-700">
            <img  src="/images/icon-white.png" alt="icon-loader" className="w-[120px] max-w-full h-[120px] max-h-full opacity-60" />
            </div>
            <div className="p-4 ">
                <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-20 mx-auto" />
            </div>

        </div>
    );
};


