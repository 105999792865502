import { useState ,useEffect } from "react";
import { ADMIN_URL, productHeaders } from "../../../constants";
import AddorEditProduct from "./dialogue/AddorEditProduct";
import useDisableBodyScroll from "../../../Hooks/useDisableBodyScroll";
import useFetchApi from "../../../Hooks/useFetchApi";
import ProductRow from "./ProductRow";
import AvailableOffers from "./dialogue/AvailableOffers";
import Loader from "../../UserComponents/Loader";
import updateArrayObjects from "../../../utils";
import Pagination from "../../UserComponents/Pagination";
import { usePaginateState } from "../../../Hooks/usePagination";
import showToast from "../../../utils/showToast";
import axiosInstance from "../../../utils/api/axiosInstance";
import { Input } from "@chakra-ui/react";

const ProductList = () => {
   const [currentPage, setCurrentPage ] =   useState(1);
   const [products ,setProducts] = useState([]);
   const [search ,setSearch] = useState('');
   const [loader , setloader] = useState(false);
   const [searching ,setSearching] = useState('');

  useEffect(() => {
    const fetchProducts = async () => {
      setProducts([]);
      setloader(true);
      axiosInstance
          .get(ADMIN_URL + "/products?&limit=20&page_no="+ currentPage + "&search="+search)
          .then((data) => {setProducts(data.data.data);  setloader(false);})
          .catch(({ response }) => showToast(response.data.message, "error"))
          .finally(() => {setloader(false);});
    };

    fetchProducts();
  }, [currentPage , searching]);

  const [productModal, setProductModal] = useState({
    show: false,
    isEditing: false,
    productData: null,
  });
  const [offerListModal, setOfferListModal] = useState({
    show: false,
    productId: null,
    isOfferActive: null,
    offerId: null,
  });
  useDisableBodyScroll(productModal.show);

  const handleNewProduct = (newProduct) => {
    setProducts((prev) => [...prev, newProduct]);
  };

  const handleEditProduct = (productData) => {
    setProductModal({
      isEditing: true,
      show: true,
      productData,
    });
  };

  const handleVisibility = () => {
    setProductModal({ show: false, isEditing: false, productData: null });
  };
  const handleOfferApply = (product) => {
    const { _id, isOfferActive, offer } = product;
    const offerId = isOfferActive ? offer?.offerId : null;
    setOfferListModal({
      show: true,
      productId: _id,
      hasOffer: isOfferActive,
      offerId,
    });
  };

    // Handle page change
    const HandlePageChange = (page) => {
      setCurrentPage(page);
    };

    const Search = () => {
      setSearching(search);
    }

  const handleOfferModalVisibility = (offerUpdatedData) => {
    if (offerUpdatedData) {
      const updatedProducts = updateArrayObjects(
        products,
        "_id",
        offerListModal.productId,
        offerUpdatedData
      );
      setProducts(updatedProducts);
    }
    setOfferListModal({
      show: false,
      productId: null,
      offerId: null,
      isOfferActive: null,
    });
  };
  return (
    <div className={`p-5 w-full h-full`}>
      <div className="flex justify-end item-center mt-10">
        <Input onChange={(e) => setSearch(e.target.value)} className="bg-slate-50 border-none !rounded-r-none !text-md !font-bold" placeholder="Search" />
        <button type="button" onClick={() => Search()} className="text-white-A700 bg-thlight focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-l-none text-sm px-10 rounded-r-lg py-2.5 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700 text-nowrap mr-5">Search</button>
        <button type="button" onClick={() => setProductModal((prev) => ({ ...prev, show: true }))} className="text-white-A700 bg-thdark focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700 text-nowrap">Add Product</button>
      </div>
      {loader ? (
        <Loader />
      ) : (
        products.length > 0 ? (
          <div className="overflow-x-auto border bg-white-A700 dark:bg-dark rounded-2xl">
            <table className="min-w-full overflow-x-auto">
              <thead>
                <tr>
                  {productHeaders.map((header, i) => (
                    <th className="py-2 px-4 border" key={i}>
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {products.length ? (
                  <>
                    {products.map((product) => (
                      <ProductRow
                        {...product}
                        key={product?._id}
                        handleEditProduct={() => handleEditProduct(product)}
                        handleApplyOfferClick={() => handleOfferApply(product)}
                      />
                    ))}
                  </>
                ) : (
                  <tr>
                    <td>
                      <h3>No products</h3>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <Pagination
              currentPage={currentPage}
              totalCount={300}
              itemsPerPage={20}
              onPageChange={(page) => HandlePageChange(page)}
            />
          </div>
        ) : (
          <div>No products</div>
        )
      )}
      {/* create product modal */}
      {productModal.show && !productModal.isEditing && (
        <AddorEditProduct
          handleNewProduct={handleNewProduct}
          handleVisibility={() =>
            setProductModal((prev) => ({
              ...prev,
              show: false,
            }))
          }
        />
      )}

      {/* Product editing modal */}
      {productModal.show && productModal.isEditing && (
        <AddorEditProduct
          isEditing={true}
          handleVisibility={handleVisibility}
          productData={productModal.productData}
        />
      )}
      {/* Available offers list */}
      {offerListModal.show && (
        <AvailableOffers
          offerData={{ type: "product", objectId: offerListModal.productId }}
          offerStatus={offerListModal}
          handlevisibilty={handleOfferModalVisibility}
        />
      )}
    </div>
  );
};

export default ProductList;
