import React from "react";
import { Box, Flex, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import BarChart from "../charts/BarChart";
import { barChartDataDailyTraffic, barChartOptionsDailyTraffic } from "../charts/variables/charts";
import { RiArrowUpSFill } from "react-icons/ri";
import Card from "../card/Card";

const getMonthName = (monthNumber) => {
  const months = {
    1: 'JAN',
    2: 'FEB',
    3: 'MAR',
    4: 'APR',
    5: 'MAY',
    6: 'JUN',
    7: 'JUL',
    8: 'AUG',
    9: 'SEP',
    10: 'OCT',
    11: 'NOV',
    12: 'DEC'
  };
  return months[monthNumber] || '';
};

export default function DailyTraffic(props) {
  const { ...rest } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");


  const monthlyData = {
    users: [],
    months: [],
    years: []
  };


  const usersPerMonth = props?.userData?.usersPerMonth.map(item => {

    monthlyData.users.push(item.count);
    monthlyData.months.push(getMonthName(item.label.month));
    monthlyData.years.push(item.label.year);

    return {
      users: item.count,
      month: getMonthName(item.label.month),
      year: item.label.year
    };
  });

  return (
    <Card align="center" direction="column" w="100%" {...rest}>
      <Flex justify="space-between" align="start" px="10px" pt="5px">
        <Flex flexDirection="column" align="start" me="20px">
          <Flex w="100%">
            <Text me="auto" color="secondaryGray.600" fontSize="sm" fontWeight="500">Users this month</Text>
          </Flex>
          <Flex align="">
            <Text color={textColor} fontSize="34px" fontWeight="700" lineHeight="100%">{props?.userData?.newUsersThisMonth}</Text>
            <Text ms="6px" color="secondaryGray.600" fontSize="sm" fontWeight="500">Users</Text>
          </Flex>
        </Flex>
        <Flex align="center"><Icon as={RiArrowUpSFill} color="green.500" />
          <Text color="green.500" fontSize="sm" fontWeight="700">+2.45%</Text></Flex>
        </Flex>
      <Box h="240px" mt="auto">
        <BarChart chartData={barChartDataDailyTraffic(monthlyData.users.reverse())} chartOptions={barChartOptionsDailyTraffic(monthlyData.months.reverse())} />
      </Box>
    </Card>
  );
}
