import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Text, Heading } from "../../../components/UserComponents";
import { NavLink, useNavigate } from "react-router-dom";
import LoginWithGoogle from "../../../components/UserComponents/Button/LoginWithGoogle";
import { useFormik } from "formik";
import { registerValidation } from "../../../utils/validations";
import { axiosPublicInstance } from "../../../utils/api/axiosInstance";
import showToast from "../../../utils/showToast";
import { setItem } from "../../../utils/localStorageUtil";
import useLoginWithGoogle from "../../../Hooks/useLoginWithGoogle";
import { USER_URL } from "../../../constants";
import { Img } from "../../../components/UserComponents/Img";

export default function Register() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: registerValidation,
    onSubmit: (values) => {
      setIsSubmitting(true);
      axiosPublicInstance
        .post(USER_URL + "/register", values)
        .then(({ data }) => {
          showToast("Registration Success, please check your email");
          setItem("otpToken", data.token);
          navigate("/otp");
        })
        .catch(({ response }) => {
          const message = response.data.message;
          showToast(message, "error");
        })
        .finally(() => setIsSubmitting(false));
    },
  });
  const { handleLoginWithGoogle } = useLoginWithGoogle();
  return (
    <>
      <Helmet>
        <title>Secure Register - Access Your Elite Digitals Account</title>
        <meta
          name="description"
          content="Log in to your Elite Digitals account to continue shopping the latest products. New user? Create an account with us for a personalized shopping experience."
        />
      </Helmet>
      <div className="bg-slate-50 h-screen flex justify-center items-center flex-col">
          <div className="container  m-auto">
            <div className="bg-slate-50 rounded-2xl overflow-hidden shadow-lg  w-4/5 md:w-full mx-auto">
            <div className="grid grid-cols-2 md:grid-cols-1 ">
              <Img src="/images/black-friday.png"  alt="blackfriday" className="h-full md:hidden"/>
              <div className="flex flex-col items-center gap-0 w-full p-5 h-full justify-center">
              <h1 className="text-5xl font-bold mb-4">Register </h1>
              <div className="flex flex-col items-center w-full">
                <form className="flex flex-col space-y-3 w-full pb-2" onSubmit={formik.handleSubmit}>
                  <div className="flex flex-col items-start gap-2 w-full">
                    <Heading as="h2">Name</Heading>
                    <input type="text" placeholder="John Doe" className="w-full !shadow-none !border-none !bg-gray-100 h-[50px] !rounded-md !px-3" {...formik.getFieldProps("name")} />
                    {formik.touched.name && formik.errors.name && <Text size="s" className="text-red-500">{formik.errors.name}</Text>}
                  </div>
                  <div className="flex flex-col items-start gap-2 w-full">
                    <Heading as="h2">Email</Heading>
                    <input type="email" placeholder="john@example.com" className="w-full !shadow-none !border-none !bg-gray-100 h-[50px] !rounded-md !px-3" {...formik.getFieldProps("email")} />
                    {formik.touched.email && formik.errors.email && <Text size="s" className="text-red-500">{formik.errors.email}</Text>}
                  </div>

                 
                  <div className="flex flex-col items-start gap-2 w-full">
                    <Heading as="h2">Password</Heading>
                    <input shape="round" type="password" placeholder="&bull;&bull;&bull;&bull;&bull;&bull;" className="w-full !shadow-none !border-none !bg-gray-100 h-[50px] !rounded-md !px-3" {...formik.getFieldProps("password")} />
                    {formik.touched.password && formik.errors.password && <Text size="s" className="text-red-500">{formik.errors.password}</Text>}
                    </div>
                  <div className="flex flex-col items-start gap-2 w-full">
                    <Heading as="h2">Confirm Password</Heading>
                    <input shape="round" type="password" placeholder="&bull;&bull;&bull;&bull;&bull;&bull;" className="w-full !shadow-none !border-none !bg-gray-100 h-[50px] !rounded-md !px-3" {...formik.getFieldProps("confirmPassword")} />
                    {formik.touched.confirmPassword && formik.errors.confirmPassword && <Text size="s" className="text-red-500">{formik.errors.confirmPassword}</Text>}
                  </div>
                

                  

                  <button type="submit" disabled={isSubmitting} className="text-white-A700 bg-thdark hover:bg-thlight focus:outline-none disabled:opacity-50 focus:ring-4 focus:ring-gray-300 text-lg font-bold rounded-lg px-5 py-2.5 me-2 mb-2">{isSubmitting ? "Submitting" : "Register"}</button>
                </form>
                <div className="flex flex-col items-center gap-2">
                  <div className="flex bg-white px-4 py-1 rounded-md shadow-inner">
                    <Text size="s" as="p" className="font-medium">
                      OR
                    </Text>
                  </div>
                  <LoginWithGoogle
                    handleLoginWithGoogle={handleLoginWithGoogle}
                  />
                  <NavLink to="/login" className="text-light_blue-600 mt-1">
                    <span className="text-blue_gray-700">
                      Already a user?&nbsp;
                    </span>
                    Login
                  </NavLink>
                </div>
              </div>
              </div>
            </div>
          </div>
          </div>
          </div>
     
    </>
  );
}
